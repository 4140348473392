@import './colors.scss';

.resume {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    max-width: 60rem;
    color: $font-color;
    font-size: large;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    h3 {
        margin: 0;
        color: $heading-color;
    }

    .ant-btn-primary {
        background-color: $resume-color;
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
        width: 100%;
        height: 4rem;
        border: none;
        border-radius: 1px;
        display: table;
        width: 100%;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
        border-radius: 1px;

        &:hover {
            background-color: $resume-highlight;
            
        }
        
        &:focus {
            background-color: $resume-color;
        }

        h3 {
            display: table-cell;
            vertical-align: middle;
        }
    }

    .resumeHeading {
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
        height: 4rem;
        background-color: $resume-color;
        border-bottom-color: #282828;
        display: table;
        width: 100%;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
        border-top-left-radius: 1px;
        border-top-right-radius: 1px;
        h3 {
            display: table-cell;
            vertical-align: middle;
        }
    }

    .resumeBody {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .resumeWrittenBody {
            background-color: $text-background;
            font-size: large;
            padding-top: 3%;
            padding-bottom: 3%;
            padding-left: 5%;
            padding-right: 5%;
            justify-content: space-evenly;
            border-bottom-left-radius: 1px;
            border-bottom-right-radius: 1px;
            box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);

            h3 {
                color: $resume-highlight;
                margin: 0;
                font-weight: 600;
            }

            h4 {
                color: $font-color;
                margin: 0;
                font-size: 18px;
                font-weight: 400;
            }

            h5 {
                color: $font-color;
                font-size: 14px;
                font-weight: 350;
                margin: 0;
            }
        }

        .resumeLeftSide {
            max-width: 26rem;
            width: 100%;

            .resumeWorkExperience, .resumeEducation {
                margin-bottom: 1rem;
            }
        }
        .resumeRightSide {
            max-width: 32rem;
            width: 100%;
            
            .resumeSkills {
                margin-bottom: 1rem;
            }
            .resumeContactBody {
                display: flex;
                justify-content: space-evenly;
                margin-bottom: 1rem;
                margin-top: 1rem;

                a.ant-btn {
                    background-color: $resume-color;
                    color: $gray-features;
                    border-style: none;
                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: column;
                    font-weight: 600;
                    font-size: 14px;
                    text-transform: uppercase;
                    padding-top: 4px;
                    width: 6rem;
                }
                .ant-btn {
                    background-color: $resume-color;
                    color: $gray-features;
                    border-style: none;
                    font-weight: 600;
                    font-size: 14px;
                    text-transform: uppercase;
                    padding-top: 4px;
                    width: 6rem;
                }
            }
        }
    }
}