@import './colors.scss';

.about {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    max-width: 40rem;
    color: $font-color;
    font-size: large;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: $text-background;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);

    .aboutHeading {
        background-color: $about-color;
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
        padding: 2.5%;
        border-top-left-radius: 1px;
        border-top-right-radius: 1px;

        h3 {
            margin: 0;
            color: $heading-color;
        }
    }

    .aboutBody {
        font-size: large;
        padding-top: 3%;
        padding-bottom: 3%;
        padding-left: 5%;
        padding-right: 5%;
        border-bottom-left-radius: 1px;
        border-bottom-right-radius: 1px;
        text-align: center;
        display: flex;
        flex-direction: column;

        p {
            text-align: justify;
        }
        .personalPhoto {
            width: 35%;
            border-style: solid;
            border-width: 2px;
            border-color: $about-color;
            border-radius: 2px;
            margin-left: 5%;
            float: right;
        }

        .aboutContact {
            display: flex;
            justify-content: space-evenly;

            a.ant-btn {
                background-color: $about-color;
                color: $gray-features;
                border-style: none;
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;
                font-weight: 600;
                font-size: 14px;
                text-transform: uppercase;
                padding-top: 4px;
                width: 6rem;
            }
            .ant-btn {
                background-color: $about-color;
                color: $gray-features;
                border-style: none;
                font-weight: 600;
                font-size: 14px;
                text-transform: uppercase;
                padding-top: 4px;
                width: 6rem;
            }
        }
    }
}