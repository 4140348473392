$gray-background: #303030;
$gray-features: #d3d3d3;
$heading-color: #f5f5f5;
$font-color: #d3d3d3;
$secondary-font-color: #9c9c9c;
$about-color: #a32e21;
$text-background: #1b1b1b;
$old-projects-color: #CA5310;
$projects-color: #2d4b9e;
$resume-color: #04776F;
$resume-highlight: #07a096;