@import './colors.scss';

.menu {
    margin-top: 7%;

    .menuItem {
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .menuDivider {
            margin-top: 0.5rem;
            margin-bottom: 0;
            border-color: $gray-features;
            border-width: 2px;
        }
    }

    h1, h2 {
        text-align: center;
        color: $gray-features;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    h1 {
        font-size: 295%;
        font-weight: 600;
    }

    h2 {
        font-size: 174%;
    }

    .ant-btn-primary {
        background-color: $gray-background;
        color: $gray-features;
        height: 5rem;
        width: 5rem;
        padding: 0;
        line-height: 2rem;
        border-width: 2px;
        margin: 1rem;

        .anticon {
            margin-top: 0.5rem;
            font-size: xx-large;
            display: block;
        }

        .anticon + span {
            margin-left: 0;
            font-weight: 600;
            font-size: small;
            text-transform: uppercase;
        }
    }

    .ant-btn-primary.aboutButton {
        border-color: $about-color;

        &:hover {
            background-color: $about-color !important;
            color: $gray-background;
        }
    }


    .ant-btn-primary.projectsButton {
        border-color: $projects-color;

        &:hover {
            background-color: $projects-color !important;
            color: $gray-background;
        }
    }

    .ant-btn-primary.resumeButton {
        border-color: $resume-color;

        &:hover {
            background-color: $resume-color !important;
            color: $gray-background;
        }
    }
}
