@import './colors.scss';

.projects {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    color: $font-color;
    font-size: large;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    .projectsBox {
        max-width: 18rem;
        width: 95%;
        background-color: $text-background;
        margin-bottom: 1rem;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
        height: 100%;

        .projectsHeading {
            h3 {
                margin: 0;
                color: $heading-color;
            }
    
            text-align: center;
            font-size: 20px;
            text-transform: uppercase;
            padding: 2.5%;
            background-color: $projects-color;
            border-top-left-radius: 1px;
            border-top-right-radius: 1px;
            
        }
    
        .projectsBody {
            font-size: large;
            padding-top: 3%;
            padding-bottom: 3%;
            padding-left: 5%;
            padding-right: 5%;
            text-align: center;
            border-bottom-left-radius: 1px;
            border-bottom-right-radius: 1px;

            .projectInfo {
                display: flex;
                justify-content: space-between;
                font-size: medium;
                color: $secondary-font-color;
                font-weight: 600;

                a.ant-btn {
                    background-color: $projects-color;
                    color: $gray-features;
                    line-height: 3rem;
                    border-style: none;
                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: column;
                    font-weight: 600;
                    font-size: 14px;
                    text-transform: uppercase;
                    padding-top: 4px;
                }
            }
        }
    }

}